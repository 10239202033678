// Generated by CoffeeScript 1.10.0
(function() {
  var assign, camelCase, capitalize, isArray, isEmpty, isFunction, isObject, isPlainObject, kebabCase, snakeCase, titleCase, words,
    slice = [].slice,
    hasProp = {}.hasOwnProperty;

  assign = function() {
    var i, key, len, source, sources, target;
    target = arguments[0], sources = 2 <= arguments.length ? slice.call(arguments, 1) : [];
    if (isFunction(Object.assign)) {
      Object.assign.apply(null, arguments);
    } else {
      for (i = 0, len = sources.length; i < len; i++) {
        source = sources[i];
        if (source != null) {
          for (key in source) {
            if (!hasProp.call(source, key)) continue;
            target[key] = source[key];
          }
        }
      }
    }
    return target;
  };

  isFunction = function(val) {
    return !!val && Object.prototype.toString.call(val) === '[object Function]';
  };

  isObject = function(val) {
    var ref;
    return !!val && ((ref = typeof val) === 'function' || ref === 'object');
  };

  isArray = function(val) {
    if (isFunction(Array.isArray)) {
      return Array.isArray(val);
    } else {
      return Object.prototype.toString.call(val) === '[object Array]';
    }
  };

  isEmpty = function(val) {
    var key;
    if (isArray(val)) {
      return !val.length;
    } else {
      for (key in val) {
        if (!hasProp.call(val, key)) continue;
        return false;
      }
      return true;
    }
  };

  isPlainObject = function(val) {
    var ctor, proto;
    return isObject(val) && (proto = Object.getPrototypeOf(val)) && (ctor = proto.constructor) && (typeof ctor === 'function') && (ctor instanceof ctor) && (Function.prototype.toString.call(ctor) === Function.prototype.toString.call(Object));
  };

  words = function(val) {
    return (val.split(/[-_\s]+|(?=[A-Z][a-z])/) || []).filter(function(n) {
      return !!n;
    });
  };

  camelCase = function(val) {
    var i, index, len, r, ref, word;
    r = '';
    ref = words(val);
    for (index = i = 0, len = ref.length; i < len; index = ++i) {
      word = ref[index];
      r += index ? capitalize(word.toLowerCase()) : word.toLowerCase();
    }
    return r;
  };

  titleCase = function(val) {
    var i, index, len, r, ref, word;
    r = '';
    ref = words(val);
    for (index = i = 0, len = ref.length; i < len; index = ++i) {
      word = ref[index];
      r += capitalize(word.toLowerCase());
    }
    return r;
  };

  kebabCase = function(val) {
    var i, index, len, r, ref, word;
    r = '';
    ref = words(val);
    for (index = i = 0, len = ref.length; i < len; index = ++i) {
      word = ref[index];
      r += (index ? '-' : '') + word.toLowerCase();
    }
    return r;
  };

  snakeCase = function(val) {
    var i, index, len, r, ref, word;
    r = '';
    ref = words(val);
    for (index = i = 0, len = ref.length; i < len; index = ++i) {
      word = ref[index];
      r += (index ? '_' : '') + word.toLowerCase();
    }
    return r;
  };

  capitalize = function(val) {
    return val.charAt(0).toUpperCase() + val.slice(1);
  };

  module.exports.assign = assign;

  module.exports.isFunction = isFunction;

  module.exports.isObject = isObject;

  module.exports.isArray = isArray;

  module.exports.isEmpty = isEmpty;

  module.exports.isPlainObject = isPlainObject;

  module.exports.camelCase = camelCase;

  module.exports.titleCase = titleCase;

  module.exports.kebabCase = kebabCase;

  module.exports.snakeCase = snakeCase;

  module.exports.capitalize = capitalize;

  module.exports.words = words;

}).call(this);
